
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Datalistmerchant from "@/components/1crm/product/detail-merchant/detail-merchant.vue";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import _Descriptions from "element-plus/lib/el-descriptions";
// import UploadComponent from "@/components/1crm/legal/legalitas/superadmin/upload-modal.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Datalistmerchant,
    // UploadComponent,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      merchantName: "",
      description: "",
    };
  },
  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);
    var route = useRoute();
    setCurrentPageBreadcrumbs("Detail Merchant", ["Data"]);
    console.log(route.params.id, "test");
    
  },
});
